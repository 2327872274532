var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-layout-container" }, [
    _c("div", { staticClass: "page-name" }, [_vm._t("page-name")], 2),
    _c(
      "div",
      { ref: "contentBox", staticClass: "page-content" },
      [_vm._t("page-content")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }