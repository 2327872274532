var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "profile-container" },
    [
      _c("PageLayout", {
        scopedSlots: _vm._u([
          {
            key: "page-name",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "nav-title font-menu-large" },
                  _vm._l(_vm.navList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "nav-title-item",
                        class: { active: item.id === _vm.currentPage },
                        on: {
                          click: function ($event) {
                            return _vm.changePage(item.id)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.title) + " ")]
                    )
                  }),
                  0
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "page-content",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _vm.currentPage === 1 && !_vm.$store.state.dashboard.isKid
                      ? _c("PersonalInformation")
                      : _vm._e(),
                    _vm.currentPage === 0 ? _c("StudentProfile") : _vm._e(),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }